<template>
    <div class="view view--protocole--add">
      <div class="view-header view-header--protocol-grouping"
        v-if="pageTitle">
        <div class="view-header__footer">
          <BtnBack :to="{ 'name': 'protocolEssaisGroupings' }" />
          <h1 class="page-title">Création d'un regroupement</h1>
        </div>
      </div>

      <div class="view-body">
        <Section class="section--border-bottom bg-gray-lightestest">
          <Container>
            <Timeline
                :index="step"
                :total="4"
                :lastStepToRight="true"
                :stepsData="stepsData"
                grouping
              />
          </Container>
        </Section>

        <form class="view-body-main-form" @submit.prevent="onSubmit(true)">
          <router-view
            v-model="submitted"
            :groupingProp="grouping"
            :pageTitle="pageTitle"
            @set-step="setStep($event)"
            @set-route="
              (event) => {
                routeName = event.routeName;
                previousRouteName = event.previousRouteName;
                nextRouteName = event.nextRouteName;
                routeParams = event.routeParams;
              }
            "
            @set-grouping="setGrouping($event)"
            >
            </router-view>

          <div class="view-footer">
            <div class="view-actions view-actions--left">
              <Btn text="Annuler"
                :to="{ name: 'protocolEssaisGroupings', params: {id: $route.params.id} }" />
              <!-- Create buttons -->
              <template v-if="!$route.name.includes('Edit')">
                <Btn v-if="previousRouteName"
                  :to="{ name: previousRouteName, params: routeParams }"
                  text="Précédent"
                  icon="chevron-big-left"
                  hollow />
                <Btn v-if="step !== 3"
                  btnType="submit"
                  text="Suivant"
                  color="primary"
                  icon="chevron-big-right"
                  icon-after />
                <Btn v-if="step === 3"
                  btnType="submit"
                  text="Créer le regroupement"
                  color="primary" />
              </template>
              <template v-else>
                <Btn
                  btnType="submit"
                  text="Enregistrer"
                  color="primary"/>
              </template>

            </div>
          </div>
        </form>
      </div>
    </div>

    <NavigationDropdownProtocol />

  </template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import BtnBack from '@/components/base/BtnBack.vue'
// import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import Timeline from '@/components/layout/Timeline.vue'
import NavigationDropdownProtocol
  from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'

export default {
  name: 'ProtocolGroupingAddEditView',

  components: {
    Btn,
    BtnBack,
    Section,
    Container,
    // TabsMaterial,
    Timeline,
    NavigationDropdownProtocol,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      submitted: false,
      step: 0,
      stepsData: ['Choix des essais', 'Choix des modalités', 'Choix des variables', 'Récapitulatif'],
      routeName: null,
      previousRouteName: null,
      nextRouteName: null,
      routeParams: {},
      method: 'post',
      componentKey: 0,
      grouping: null,
    }
  },

  watch: {
    previousRouteName() {
      this.$nextTick(() => {
        this.componentKey += 1
      })
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.method = 'put'
    }
  },

  methods: {
    setGrouping(event) {
      this.grouping = event
    },
    onSubmit(submitted = false) {
      this.submitted = submitted
    },

    setStep(event) {
      this.step = event
    },

  },
}
</script>

<style lang="scss" scoped>

::v-deep(.btn-primary) {
  background-color: $color-protocol-grouping;

  @include hocus() {
    background-color: lighten($color-protocol-grouping, 10%) !important;
  }
}

</style>
